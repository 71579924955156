import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Fragment, useMemo } from "react";
import { useLingui } from "@lingui/react";
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Grid,
  Group,
  HoverCard,
  LoadingOverlay,
  Paper,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import {
  createFileRoute,
  useBlocker,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import dayjs from "dayjs";
import {
  createMRTColumnHelper,
  MantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  useMantineReactTable,
} from "mantine-react-table";
import { z } from "zod";

import UpdateAdditionalInformationModal from "@/features/bookings/update-booking/update-additional-information-modal";
import { bookingStatusLabels } from "@/features/bookings/utils/status";
import { bookingVisitCategoryLabels } from "@/features/bookings/utils/visit-category";
import useCustomerForm, {
  FormInitial,
} from "@/features/customers/update-customer/use-update-customer-form";
import { useGetBookings } from "@/shared/api/generated/booking";
import { useGetCustomerbyCustomerNumber } from "@/shared/api/generated/customers";
import {
  GetBookingsItem,
  GetCustomerData,
} from "@/shared/api/generated/schemas";
import { AnchorLink } from "@/shared/components/AnchorLink";
import { getDefaultMRTOptions } from "@/shared/components/table/defaultMRTOptions";
import { countryListnbNO } from "@/shared/utils/countryList";

const bookingsSearchSchema = z.object({
  includeCancelled: fallback(z.boolean().optional(), undefined),
});

export const Route = createFileRoute("/_auth-layout/customers/$customerId/")({
  component: CustomerDetailsPage,
  validateSearch: zodValidator(bookingsSearchSchema),
});

function CustomerDetailsPage() {
  const { customerId } = Route.useParams();

  const { data } = useGetCustomerbyCustomerNumber(parseInt(customerId));

  return (
    <Stack gap="lg" p="lg" pos="relative" mih="30%">
      {data?.data ? (
        <CustomerDetails customerId={customerId} customerData={data.data} />
      ) : (
        <LoadingOverlay visible={true} />
      )}
      <CustomerBookings customerId={customerId} />
    </Stack>
  );
}

function CustomerDetails({
  customerId,
  customerData,
}: {
  customerId: string;
  customerData: GetCustomerData;
}) {
  const { i18n } = useLingui();

  const { form, handleSubmit } = useCustomerForm(customerId, customerData);

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  const isInvoiceAddressTheSameAsAddress =
    form.getValues().isInvoiceAddressTheSameAsAddress;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group justify="space-between">
        <h1>
          <Trans>{customerData.name}</Trans>
        </h1>

        <Stack gap={0}>
          <Text size="sm" c="dimmed">
            {customerData.createdAt ? (
              <Trans>
                Opprettet:{" "}
                {i18n.date(customerData.createdAt, {
                  dateStyle: "short",
                  timeStyle: "long",
                })}
              </Trans>
            ) : null}
          </Text>
          <Text size="sm" c="dimmed">
            {customerData.updatedAt ? (
              <Trans>
                Sist endret:{" "}
                {i18n.date(customerData.updatedAt, {
                  dateStyle: "short",
                  timeStyle: "long",
                })}
              </Trans>
            ) : null}
          </Text>
        </Stack>
      </Group>

      <Grid align="stretch">
        <Grid.Col span={4}>
          <Paper p="xs" h="100%">
            <Text size="sm" mb="sm">
              <Trans>Detaljer og kontaktinformasjon</Trans>
            </Text>

            <Stack gap="sm">
              <Group grow>
                <TextInput
                  label={t`Navn`}
                  key={form.key("name")}
                  {...form.getInputProps("name")}
                />
              </Group>

              <Group grow align="start">
                <Select
                  label={t`Land`}
                  data={countryListnbNO}
                  searchable
                  clearable
                  key={form.key("country")}
                  {...form.getInputProps("country")}
                />
                <TextInput
                  label={t`Orgnum./EHF`}
                  key={form.key("organizationNumber")}
                  {...form.getInputProps("organizationNumber")}
                />
              </Group>
              <Group grow align="start">
                <TextInput
                  label={t`Telefon`}
                  key={form.key("phone")}
                  {...form.getInputProps("phone")}
                />
                <TextInput
                  label={t`Epost`}
                  key={form.key("email")}
                  {...form.getInputProps("email")}
                />
              </Group>
              <Textarea
                label={t`Extra informasjon`}
                placeholder={t`Extra informasjon`}
                key={form.key("information")}
                {...form.getInputProps("information")}
                autosize
                minRows={3}
              />
            </Stack>
          </Paper>
        </Grid.Col>

        <Grid.Col span={4}>
          <Paper p="xs" h="100%">
            <Text size="sm" mb="sm">
              <Trans>Gate- og fakturaadresse</Trans>
            </Text>

            <Stack gap="sm">
              <Group grow>
                <TextInput
                  label={t`Gateadresse`}
                  key={form.key("address")}
                  {...form.getInputProps("address")}
                  onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    form.getInputProps("address").onChange(event);
                    if (isInvoiceAddressTheSameAsAddress) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      form.getInputProps("invoiceAddress").onChange(event);
                    }
                  }}
                />
              </Group>
              <Group grow>
                <TextInput
                  label={t`Postnummer`}
                  key={form.key("postCode")}
                  {...form.getInputProps("postCode")}
                  onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    form.getInputProps("postCode").onChange(event);
                    if (isInvoiceAddressTheSameAsAddress) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      form.getInputProps("invoicePostCode").onChange(event);
                    }
                  }}
                />
                <TextInput
                  label={t`Sted`}
                  key={form.key("city")}
                  {...form.getInputProps("city")}
                  onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    form.getInputProps("city").onChange(event);
                    if (isInvoiceAddressTheSameAsAddress) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      form.getInputProps("invoiceCity").onChange(event);
                    }
                  }}
                />
              </Group>
              <Switch
                label={t`Samme som gateadresse`}
                key={form.key("isInvoiceAddressTheSameAsAddress")}
                {...form.getInputProps("isInvoiceAddressTheSameAsAddress", {
                  type: "checkbox",
                })}
              />
              <Collapse in={!isInvoiceAddressTheSameAsAddress}>
                <TextInput
                  label={t`Fakturaadresse`}
                  disabled={isInvoiceAddressTheSameAsAddress}
                  key={form.key("invoiceAddress")}
                  {...form.getInputProps("invoiceAddress")}
                />
                <Group grow>
                  <TextInput
                    label={t`Postnummer`}
                    disabled={isInvoiceAddressTheSameAsAddress}
                    key={form.key("invoicePostCode")}
                    {...form.getInputProps("invoicePostCode")}
                  />
                  <TextInput
                    label={t`Sted`}
                    disabled={isInvoiceAddressTheSameAsAddress}
                    key={form.key("invoiceCity")}
                    {...form.getInputProps("invoiceCity")}
                  />
                </Group>
              </Collapse>
            </Stack>
          </Paper>
        </Grid.Col>

        <Grid.Col span={4}>
          <Paper p="xs" h="100%">
            <Flex direction="column" h="100%">
              <Text size="sm" mb="sm">
                <Trans>Kontaktpersoner</Trans>
              </Text>

              <Stack style={{ flex: 1 }} justify="space-between">
                <Stack gap="xs">
                  {form.getValues().contactPeople?.map((person, index) => (
                    <Group gap="sm" key={person.id}>
                      <TextInput
                        style={{ flex: 1 }}
                        placeholder={t`Navn`}
                        key={form.key(`contactPeople.${index}.name`)}
                        {...form.getInputProps(`contactPeople.${index}.name`)}
                      />
                      <Flex align="center">
                        <ActionIcon
                          variant="outline"
                          color="red"
                          onClick={() => {
                            form.removeListItem("contactPeople", index);
                            // if (form.getValues().contactPeople?.length === 0) {
                            //   form.insertListItem("contactPeople", {
                            //     id: randomId(),
                            //     name: "",
                            //   } satisfies FormInitial["contactPeople"][0]);
                            // }
                          }}
                        >
                          <IconMinus />
                        </ActionIcon>
                      </Flex>
                    </Group>
                  ))}
                </Stack>

                <Box>
                  <Button
                    variant="outline"
                    leftSection={<IconPlus />}
                    onClick={() => {
                      form.insertListItem("contactPeople", {
                        id: randomId(),
                        name: "",
                      } satisfies FormInitial["contactPeople"][0]);
                    }}
                  >
                    <Trans>Legg til person</Trans>
                  </Button>
                </Box>
              </Stack>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </form>
  );
}

function CustomerBookings({ customerId }: { customerId: string }) {
  const { i18n } = useLingui();

  const { includeCancelled } = useSearch({
    from: "/_auth-layout/customers/$customerId/",
  });
  const navigate = useNavigate({ from: "/customers/$customerId" });

  const { data, isLoading } = useGetBookings({
    includeProgram: true,
    includeCancelled: includeCancelled ?? false,
    customers: [customerId],
  });

  const ch = createMRTColumnHelper<GetBookingsItem>();

  const columns = [
    ch.accessor("id", {
      header: "#",
      Cell: ({ cell, renderedCellValue }) => (
        <AnchorLink
          to="/bookings/$bookingId"
          params={{ bookingId: cell.getValue().toString() }}
        >
          {renderedCellValue}
        </AnchorLink>
      ),
      size: 90,
    }),
    ch.accessor(
      (row) =>
        i18n.date(row.arrival, { dateStyle: "short", timeStyle: "short" }),
      {
        id: "arrival",
        header: t`Ankomst`,
        filterVariant: "date-range",
        filterFn: (row, _columnId, filterValue) => {
          const [start, end] = filterValue as [
            Date | undefined | "",
            Date | undefined | "",
          ];

          // not set - Date
          if (!start && end) {
            return dayjs(row.original.arrival).isSameOrBefore(end, "day");
          }

          // Date - not set
          if (start && !end) {
            return dayjs(row.original.arrival).isSameOrAfter(start, "day");
          }

          // Date - Date
          if (start && end) {
            return dayjs(row.original.arrival).isBetween(
              start,
              end,
              "day",
              "[]",
            );
          }

          return true;
        },
      },
    ),
    ch.accessor("group.guests.booked", {
      header: t`Antall`,
      filterVariant: "range",
      size: 120,
    }),
    ch.accessor(
      (row) =>
        row.visitCategory
          ? i18n.t(bookingVisitCategoryLabels[row.visitCategory]) ||
            row.visitCategory
          : "-",
      {
        header: t`Type`,
        filterVariant: "select",
      },
    ),
    ch.accessor("program.id", {
      header: t`Program`,
      Cell: ({ row }) => <ProgramCell program={row.original.program} />,
      enableColumnFilter: false,
      minSize: 300,
    }),
    ch.accessor(
      (row) =>
        row.status
          ? i18n.t(bookingStatusLabels[row.status]) || row.status
          : "-",
      {
        header: t`Status`,
        filterVariant: "multi-select",
      },
    ),
    ch.accessor(
      (row) =>
        row.guide ? `${row.guide.firstName} ${row.guide.lastName ?? ""}` : "-",
      {
        header: t`Guide`,
      },
    ),
    ch.display({
      id: "comment",
      header: "",
      Cell: ({ row }) => (
        <UpdateAdditionalInformationModal booking={row.original} />
      ),
      enableColumnActions: false,
      size: 60,
      mantineTableHeadCellProps: {
        align: "right",
      },
      mantineTableBodyCellProps: {
        align: "right",
      },
    }),
  ];

  const defaultMRTOptions = getDefaultMRTOptions<GetBookingsItem>();

  const table = useMantineReactTable({
    data: useMemo(() => data?.data ?? [], [data?.data]),
    columns,
    ...defaultMRTOptions,
    renderTopToolbar: ({ table }) => (
      <>
        <Flex justify="space-between" p="md">
          <Group gap="xs">
            <MRT_GlobalFilterTextInput variant="default" mx={0} table={table} />
            <Switch
              label="Inkluder avlyste"
              checked={includeCancelled ?? false}
              onChange={(event) =>
                navigate({
                  search: { includeCancelled: event.currentTarget.checked },
                })
              }
            />
          </Group>

          <Group gap="xs">
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Group>
        </Flex>
        <Divider />
      </>
    ),
    enableFacetedValues: true,
    state: {
      isLoading,
    },
    mantinePaperProps: {
      shadow: "none",
      withBorder: false,
    },
    mantineTableProps: {
      layout: "fixed",
    },
  });

  return (
    <Paper>
      <Text size="sm" px="xs" pt="xs">
        <Trans>Bookinger</Trans>
      </Text>

      <MantineReactTable table={table} />
    </Paper>
  );
}

function ProgramCell({ program }: { program: GetBookingsItem["program"] }) {
  const { i18n } = useLingui();

  const programStringified = program?.map((program) => {
    return `${program.productNameNO ?? "-"}${program.start || program.end ? " " : ""}${
      program.start ? i18n.date(program.start, { timeStyle: "short" }) : ""
    }${program.start || program.end ? "–" : ""}${program.end ? i18n.date(program.end, { timeStyle: "short" }) : ""}`;
  });

  if (!program || program.length === 0) {
    return "-";
  }

  return (
    <HoverCard withArrow>
      <HoverCard.Target>
        <Text size="sm" truncate="end">
          {programStringified?.join(", ") ?? "-"}
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown p="xs">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: 8,
            rowGap: 4,
          }}
        >
          {program?.map((product) => (
            <Fragment key={product.id}>
              <Text size="xs" ta={!product.start ? "right" : "left"}>
                {product.start
                  ? i18n.date(product.start, {
                      timeStyle: "short",
                    })
                  : ""}
                {product.start || product.end ? "–" : ""}
                {product.end
                  ? i18n.date(product.end, { timeStyle: "short" })
                  : ""}
              </Text>
              <Stack gap={0}>
                <Text size="xs">{product.productNameNO}</Text>
                <Text size="xs" c="dimmed">
                  {product.comment}
                </Text>
              </Stack>
            </Fragment>
          ))}
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
