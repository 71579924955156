import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  Button,
  Group,
  MultiSelect,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  getGetEmployeesQueryKey,
  useCreateEmployee,
} from "@/shared/api/generated/employee";
import { useGetLocations } from "@/shared/api/generated/locations";
import { emptyStringToNull } from "@/shared/utils/forms/emptyStringToNull";

const FormSchema = z.object({
  firstName: z.string().min(1, { message: t`Obligatorisk` }),
  lastName: z.string(),
  email: z
    .string()
    .email({ message: t`Ugyldig epostadresse` })
    .min(1, { message: t`Obligatorisk` }),
  phoneNumber: z.string(),
  locations: z.array(z.string().min(1, { message: t`Velg en lokasjoner` })),
});

type FormInitial = z.input<typeof FormSchema>;
type FormSubmit = z.output<typeof FormSchema>;

interface Props {
  close: () => void;
}

export default function CreateEmployeeForm({ close }: Props) {
  const queryClient = useQueryClient();
  const { data: locations } = useGetLocations();

  const { mutate: createEmployee } = useCreateEmployee({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetEmployeesQueryKey(),
        });
        close();
      },
    },
  });

  const form = useForm<FormInitial, (v: FormInitial) => FormSubmit>({
    mode: "uncontrolled",
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      locations: [],
    },
    validate: zodResolver(FormSchema),
  });

  function handleSubmit(values: FormSubmit) {
    createEmployee({
      data: {
        firstName: values.firstName,
        lastName: emptyStringToNull(values.lastName),
        email: values.email,
        phoneNumber: emptyStringToNull(values.phoneNumber),
        locations: values.locations.length ? values.locations : null,
      },
    });
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Text size="sm" c="dimmed" mb={8}>
        <Trans>Fyll ut den informasjonen du har tilgjengelig.</Trans>
      </Text>

      <Stack>
        <Group grow align="flex-start">
          <TextInput
            label={t`Fornavn`}
            placeholder={t`Fornavn`}
            withAsterisk
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label={t`Etternavn`}
            placeholder={t`Etternavn`}
            {...form.getInputProps("lastName")}
          />
        </Group>

        <Group grow align="flex-start">
          <TextInput
            label={t`Epostadresse`}
            placeholder={t`Epostadresse`}
            withAsterisk
            {...form.getInputProps("email")}
          />

          <TextInput
            label={t`Telefonnummer`}
            placeholder={t`Telefonnummer`}
            {...form.getInputProps("phoneNumber")}
          />
        </Group>

        <MultiSelect
          label={t`Lokasjon`}
          placeholder={t`Velg lokasjoner`}
          data={
            locations?.data?.map((location) => ({
              value: location.id,
              label: location.name,
            })) ?? []
          }
          clearable
          searchable
          {...form.getInputProps("locations")}
        />

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" onClick={close}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
