/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateCustomerRequest,
  CreateCustomerResponse,
  GetCustomerResponse,
  GetCustomersResponse,
  UpdateCustomerRequest,
  UpdateCustomerResponse
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Create Customer
 */
export const useCreateCustomerHook = () => {
        const createCustomer = useApi<CreateCustomerResponse>();

        return useCallback((
    createCustomerRequest: BodyType<CreateCustomerRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return createCustomer(
          {url: `/customer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCustomerRequest, signal
    },
          options);
        }, [createCustomer])
      }
    


export const useCreateCustomerMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>, TError,{data: BodyType<CreateCustomerRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>, TError,{data: BodyType<CreateCustomerRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createCustomer =  useCreateCustomerHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>, {data: BodyType<CreateCustomerRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCustomerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>>
    export type CreateCustomerMutationBody = BodyType<CreateCustomerRequest>
    export type CreateCustomerMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Customer
 */
export const useCreateCustomer = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>, TError,{data: BodyType<CreateCustomerRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateCustomerHook>>>,
        TError,
        {data: BodyType<CreateCustomerRequest>},
        TContext
      > => {

      const mutationOptions = useCreateCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Customer
 */
export const useDeleteCustomerHook = () => {
        const deleteCustomer = useApi<void>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return deleteCustomer(
          {url: `/customer/${id}`, method: 'DELETE'
    },
          options);
        }, [deleteCustomer])
      }
    


export const useDeleteCustomerMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const deleteCustomer =  useDeleteCustomerHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCustomer(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCustomerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>>
    
    export type DeleteCustomerMutationError = ErrorType<ApiAppError>

    /**
 * @summary Delete Customer
 */
export const useDeleteCustomer = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteCustomerHook>>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = useDeleteCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Customer by Customer Number
 */
export const useGetCustomerbyCustomerNumberHook = () => {
        const getCustomerbyCustomerNumber = useApi<GetCustomerResponse>();

        return useCallback((
    id: number,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getCustomerbyCustomerNumber(
          {url: `/customer/${id}`, method: 'GET', signal
    },
          options);
        }, [getCustomerbyCustomerNumber])
      }
    

export const getGetCustomerbyCustomerNumberQueryKey = (id: number,) => {
    return [`/customer/${id}`] as const;
    }

    
export const useGetCustomerbyCustomerNumberQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError = ErrorType<ApiAppError>>(id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerbyCustomerNumberQueryKey(id);

  const getCustomerbyCustomerNumber =  useGetCustomerbyCustomerNumberHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>> = ({ signal }) => getCustomerbyCustomerNumber(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetCustomerbyCustomerNumberQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>>
export type GetCustomerbyCustomerNumberQueryError = ErrorType<ApiAppError>


export function useGetCustomerbyCustomerNumber<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCustomerbyCustomerNumber<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCustomerbyCustomerNumber<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Customer by Customer Number
 */

export function useGetCustomerbyCustomerNumber<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError = ErrorType<ApiAppError>>(
 id: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerbyCustomerNumberHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = useGetCustomerbyCustomerNumberQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Customer
 */
export const useUpdateCustomerHook = () => {
        const updateCustomer = useApi<UpdateCustomerResponse>();

        return useCallback((
    id: number,
    updateCustomerRequest: BodyType<UpdateCustomerRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateCustomer(
          {url: `/customer/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateCustomerRequest
    },
          options);
        }, [updateCustomer])
      }
    


export const useUpdateCustomerMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>, TError,{id: number;data: BodyType<UpdateCustomerRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>, TError,{id: number;data: BodyType<UpdateCustomerRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateCustomer =  useUpdateCustomerHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>, {id: number;data: BodyType<UpdateCustomerRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCustomer(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCustomerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>>
    export type UpdateCustomerMutationBody = BodyType<UpdateCustomerRequest>
    export type UpdateCustomerMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Customer
 */
export const useUpdateCustomer = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>, TError,{id: number;data: BodyType<UpdateCustomerRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateCustomerHook>>>,
        TError,
        {id: number;data: BodyType<UpdateCustomerRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Customers
 */
export const useGetCustomersHook = () => {
        const getCustomers = useApi<GetCustomersResponse>();

        return useCallback((
    
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getCustomers(
          {url: `/customers`, method: 'GET', signal
    },
          options);
        }, [getCustomers])
      }
    

export const getGetCustomersQueryKey = () => {
    return [`/customers`] as const;
    }

    
export const useGetCustomersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError = ErrorType<ApiAppError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomersQueryKey();

  const getCustomers =  useGetCustomersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>> = ({ signal }) => getCustomers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetCustomersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>>
export type GetCustomersQueryError = ErrorType<ApiAppError>


export function useGetCustomers<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError = ErrorType<ApiAppError>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCustomers<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetCustomers<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Customers
 */

export function useGetCustomers<TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = useGetCustomersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



