import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { useGetCategories } from "@/shared/api/generated/categories";
import { useGetLocations } from "@/shared/api/generated/locations";
import {
  getGetProductsQueryKey,
  useUpdateProduct,
} from "@/shared/api/generated/products";
import { GetProductsItem } from "@/shared/api/generated/schemas";
import { useFormWatch } from "@/shared/utils/forms/useFormWatch";

import { newProductFormSchema } from "../create-product/form-schema";
import {
  minutesToProductDuration,
  productDurationToMinutes,
} from "../utils/product-duration";

type UpdateProductFormValues = z.infer<typeof newProductFormSchema>;

export default function UpdateProductForm({
  product,
  onSuccessCallback,
  onClose,
}: {
  product: GetProductsItem;
  onSuccessCallback?: () => void;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateProduct } = useUpdateProduct({
    mutation: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getGetProductsQueryKey() }),
    },
  });

  const form = useForm<UpdateProductFormValues>({
    mode: "uncontrolled",
    validate: zodResolver(newProductFormSchema),
    initialValues: {
      nameEnglish: product.nameEN,
      nameNorwegian: product.nameNO,
      categoryId: product.category.id,
      locationId: product.location.id,
      withPrice: !!product.price,
      price: product.price ?? 0,
      withDuration: !!product.duration,
      duration: product.duration
        ? minutesToProductDuration(product.duration)
        : "",
    },
  });

  const withPrice = useFormWatch(form, "withPrice");
  const withDuration = useFormWatch(form, "withDuration");

  const { data: locations } = useGetLocations();
  const { data: productCategories } = useGetCategories();

  async function handleSubmit(values: UpdateProductFormValues) {
    await updateProduct(
      {
        id: product.id,
        data: {
          nameEN: values.nameEnglish,
          nameNO: values.nameNorwegian,
          categoryID: values.categoryId,
          locationID: values.locationId,
          hasPrice: values.withPrice,
          price: values.withPrice ? values.price : null,
          hasDuration: values.withDuration,
          duration: values.withDuration
            ? productDurationToMinutes(values.duration)
            : null,
        },
      },
      {
        onSuccess: onSuccessCallback,
      },
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Select
          label={t`Lokasjon`}
          placeholder={t`Velg lokasjon`}
          data={locations?.data?.map((location) => ({
            value: location.id,
            label: location.name,
          }))}
          searchable
          withAsterisk
          clearable
          description={t`Produktet kan kun gjelde for en lokasjon om gangen, for å muliggjøre forskjellig prising eller varighet per lokasjon. Dersom samme type produkt skal gjelde på flere lokasjoner, må det opprettes ett produkt per lokasjon.`}
          {...form.getInputProps("locationId")}
        />

        <Group grow align="flex-start">
          <TextInput
            label={t`Navn (norsk)`}
            placeholder={t`Velg navn`}
            withAsterisk
            {...form.getInputProps("nameNorwegian")}
          />
          <TextInput
            label={t`Navn (engelsk)`}
            placeholder={t`Velg navn`}
            withAsterisk
            {...form.getInputProps("nameEnglish")}
          />
        </Group>

        <Group grow align="flex-start">
          <Select
            label={t`Kategori`}
            placeholder={t`Kategori`}
            data={productCategories?.data?.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            searchable
            withAsterisk
            clearable
            description={t`Velg kategorien som passer best for dette produktet.`}
            {...form.getInputProps("categoryId")}
          />
        </Group>

        <Group grow align="flex-start">
          <Switch
            mt={33}
            label={t`Har en varighet`}
            {...form.getInputProps("withDuration", { type: "checkbox" })}
          />
          <TimeInput
            label={t`Varighet`}
            withAsterisk={withDuration}
            disabled={!withDuration}
            {...form.getInputProps("duration")}
          />
        </Group>

        <Group grow align="flex-start">
          <Switch
            mt={33}
            label={t`Har en pris`}
            {...form.getInputProps("withPrice", { type: "checkbox" })}
          />
          <NumberInput
            label={t`Pris per stykk`}
            withAsterisk={withPrice}
            disabled={!withPrice}
            allowNegative={false}
            allowDecimal={false}
            hideControls
            {...form.getInputProps("price")}
          />
        </Group>

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" onClick={onClose}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
