import { t } from "@lingui/core/macro";
import { notifications } from "@mantine/notifications";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorType } from "@/shared/api/client";

export const defaultOnErrorHandler = (
  error: ErrorType<{
    error: string;
  }>,
) => {
  console.error(error);
  notifications.show({
    title: t`Feil`,
    message: error.response?.data.error ?? error.message,
    color: "red",
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // important as forms do not reinitialize even if they get new data
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => {
        if (error.status === 409) {
          notifications.show({
            title: t`Lagringskonflikt`,
            message: t`Det er en nyere versjon av denne bookingen. Last siden på nytt for å gjøre endringer.`,
            color: "red",
          });
          return;
        }
        defaultOnErrorHandler(error);
      },
    },
  },
  queryCache: new QueryCache({
    // More info on error handling: https://tkdodo.eu/blog/react-query-error-handling
    onError: defaultOnErrorHandler,
  }),
});

function ReactQueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default ReactQueryProvider;
declare module "@tanstack/react-query" {
  interface Register {
    defaultError: ErrorType<{ error: string }>;
  }
}
