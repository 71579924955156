import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import { type I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import {
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";
import { z } from "zod";

import {
  getGetBookingQueryKey,
  getGetBookingsQueryKey,
  useUpdateBookingGroupInformation,
} from "@/shared/api/generated/booking";
import {
  GetBookingData,
  GetBookingResponse,
  GetBookingsResponse,
} from "@/shared/api/generated/schemas";
import { countryListnbNO } from "@/shared/utils/countryList";
import { langugeListnbNO } from "@/shared/utils/languageList";

const groupInformationFormSchema = z.object({
  groupName: z.string(),
  contactPersonName: z.string(),
  contactPersonPhone: z.string(),
  nationalities: z.array(
    z.string().min(1, { message: t`Velg nasjonaliteter` }),
  ),
  languages: z.array(z.string().min(1, { message: t`Velge språk` })),
  guestsNumber: z.number().min(0, { message: t`Velg antall` }),
  guestsNumberArrived: z.number().min(0, { message: t`Velg antall` }),
  travelLeadersNumber: z.number().min(0, { message: t`Velg antall` }),
});

type GroupInformationFormInitial = z.input<typeof groupInformationFormSchema>;
type GroupInformationFormSubmit = z.output<typeof groupInformationFormSchema>;

const favouriteLanguages = ["no", "en", "de", "fr", "es", "it", "pl", "nl"];

const groupedLanguageList = (i18n: I18n) => [
  {
    group: i18n.t(msg`Ofte brukt`),
    items: langugeListnbNO.filter((lang) =>
      favouriteLanguages.includes(lang.value),
    ),
  },
  {
    group: i18n.t(msg`Annen`),
    // Filter out languages that are already in favorites
    items: langugeListnbNO.filter(
      (lang) => !favouriteLanguages.includes(lang.value),
    ),
  },
];

const favouriteCountries = [
  "NO",
  "SE",
  "DK",
  "FI",
  "IS",
  "DE",
  "GB",
  "FR",
  "ES",
  "IT",
  "PL",
  "NL",
];

const groupedCountryList = (i18n: I18n) => [
  {
    group: i18n.t(msg`Ofte brukt`),
    items: countryListnbNO.filter((country) =>
      favouriteCountries.includes(country.value),
    ),
  },
  {
    group: i18n.t(msg`Annen`),
    items: countryListnbNO.filter(
      (country) => !favouriteCountries.includes(country.value),
    ),
  },
];

export default function GroupInformation({
  booking,
}: {
  booking: GetBookingData;
}) {
  const { i18n } = useLingui();

  const debouncedSubmit = useDebouncedCallback(() => {
    form.onSubmit(handleSubmit)();
  }, 1000);

  const form = useForm<
    GroupInformationFormInitial,
    () => GroupInformationFormSubmit
  >({
    validate: zodResolver(groupInformationFormSchema),
    initialValues: {
      groupName: booking.group?.name ?? "",
      contactPersonName: booking.group?.contactPerson ?? "",
      contactPersonPhone: booking.group?.phone ?? "",
      nationalities: booking.group?.nationalities ?? [],
      languages: booking.group?.languages ?? [],
      guestsNumber: booking.group?.guests.booked ?? 0,
      guestsNumberArrived: booking.group?.guests.arrived ?? 0,
      travelLeadersNumber: booking.group?.travelLeader.booked ?? 0,
    },
    onValuesChange: debouncedSubmit,
  });

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateBookingGroupInformation } =
    useUpdateBookingGroupInformation({
      mutation: {
        onSuccess: (data) => {
          queryClient.setQueryData<GetBookingResponse>(
            getGetBookingQueryKey(booking.id),
            data,
          );
          // Update this booking in the for GET /bookings
          queryClient.setQueriesData<GetBookingsResponse>(
            { queryKey: getGetBookingsQueryKey() },
            (old) => ({
              ...old,
              data: (old?.data ?? []).map((booking) =>
                booking.id === data.data.id ? data.data : booking,
              ),
            }),
          );
        },
      },
    });

  async function handleSubmit(values: GroupInformationFormInitial) {
    await updateBookingGroupInformation({
      id: booking.id,
      data: {
        name: values.groupName,
        contactPerson: values.contactPersonName,
        phone: values.contactPersonPhone,
        nationalities: values.nationalities,
        languages: values.languages,
        guestsBooked: values.guestsNumber,
        guestsArrived: values.guestsNumberArrived,
        travelLeadersBooked: values.travelLeadersNumber,
        updatedAt: booking.updatedAt,
      },
    });
    form.resetDirty(values);
  }

  return (
    <Paper p="xs" h={"100%"}>
      <Text size="sm" mb="sm">
        <Trans>Gruppeinformasjon</Trans>
      </Text>

      <Stack gap="sm">
        <TextInput
          label={t`Gruppe`}
          placeholder={t`Gruppe`}
          {...form.getInputProps("groupName")}
          key={form.key("groupName")}
        />

        <Group grow align="flex-start">
          <TextInput
            label={t`Kontaktperson`}
            placeholder={t`Kontaktperson`}
            {...form.getInputProps("contactPersonName")}
            key={form.key("contactPersonName")}
          />
          <TextInput
            label={t`Mobil`}
            placeholder={t`Mobilnummer`}
            {...form.getInputProps("contactPersonPhone")}
            key={form.key("contactPersonPhone")}
          />
        </Group>

        <Group grow align="flex-start">
          <MultiSelect
            label={t`Nasjonalitet`}
            placeholder={t`Nasjonalitet`}
            data={groupedCountryList(i18n)}
            searchable
            clearable
            {...form.getInputProps("nationalities")}
            key={form.key("nationalities")}
          />
          <MultiSelect
            label={t`Språk`}
            placeholder={t`Språk`}
            data={groupedLanguageList(i18n)}
            searchable
            clearable
            {...form.getInputProps("languages")}
            key={form.key("languages")}
          />
        </Group>

        <Group grow align="flex-start">
          <NumberInput
            label={t`Antall`}
            {...form.getInputProps("guestsNumber")}
            key={form.key("guestsNumber")}
          />
          <NumberInput
            label={t`Reiseleder`}
            {...form.getInputProps("travelLeadersNumber")}
            key={form.key("travelLeadersNumber")}
          />
          <NumberInput
            label={t`Ankommet`}
            {...form.getInputProps("guestsNumberArrived")}
            key={form.key("guestsNumberArrived")}
          />
        </Group>
      </Stack>
    </Paper>
  );
}
