import { t } from "@lingui/core/macro";
import React, { Suspense } from "react";
import { UserButton } from "@clerk/clerk-react";
import {
  ActionIcon,
  Box,
  Center,
  Divider,
  Flex,
  Loader,
  NavLink,
  NavLinkProps,
  Paper,
  PaperProps,
  Stack,
  Tooltip,
} from "@mantine/core";
import {
  IconCreditCard,
  IconMapPin,
  IconReceipt,
  IconStar,
  IconUsers,
  type Icon as IconType,
} from "@tabler/icons-react";
import {
  createFileRoute,
  createLink,
  CreateLinkProps,
  Link,
  Outlet,
  redirect,
} from "@tanstack/react-router";

import icon from "@/assets/icon-mn.svg";
import logo from "@/assets/logo-mn.svg";

export const Route = createFileRoute("/_auth-layout")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isSignedIn) {
      redirect({
        to: "/login/$",
        search: {
          redirect: location.href,
        },
        throw: true,
      });
    }
  },
  component: MainLayout,
});

export function MainLayout() {
  return (
    <Flex className="h-screen w-screen">
      {/* <MobileNavbar hiddenFrom="md" /> */}
      <DesktopNavbar />

      <Box style={{ overflowX: "hidden", flex: 1 }}>
        <Suspense
          fallback={
            <Center h="100%">
              <Loader />
            </Center>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </Flex>
  );
}

const NavRouterLink = createLink(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      props: CreateLinkProps & NavLinkProps,
      ref: React.ForwardedRef<HTMLAnchorElement>,
    ) => {
      return (
        <NavLink
          rightSection={<></>}
          opened={true}
          {...props}
          ref={ref}
          renderRoot={Link}
        />
      );
    },
  ),
);

function DesktopNavbar(props: PaperProps) {
  return (
    <Paper
      withBorder
      component="nav"
      w={200}
      className="flex-shrink-0"
      radius={0}
      {...props}
    >
      <Stack h={"100%"} gap={0}>
        <Center p={16} py={32} pt={42}>
          <img src={logo} alt="Museum Nord" className="h-9" />
        </Center>

        <Divider m={0} />

        <Stack flex={1} pt={16} gap={0}>
          <NavRouterLink
            to="/bookings"
            label={t`Bookinger`}
            leftSection={<IconReceipt />}
          />
          <NavRouterLink
            to="/products"
            label={t`Produkt`}
            leftSection={<IconStar />}
          >
            <NavRouterLink
              to="/products"
              label={t`Alle produkt`}
              activeOptions={{ exact: true }}
              variant="subtle"
            />
            <NavRouterLink
              to="/products/categories"
              label={t`Kategorier`}
              variant="subtle"
            />
          </NavRouterLink>

          <NavRouterLink
            to="/employees"
            label={t`Personell`}
            leftSection={<IconUsers />}
          />

          <NavRouterLink
            to="/customers"
            label={t`Kunderegister`}
            leftSection={<IconCreditCard />}
          />

          <NavRouterLink
            to="/locations"
            label={t`Lokasjoner`}
            leftSection={<IconMapPin />}
          />
        </Stack>

        <Divider />

        <Flex p={16} align="center" bg="white">
          <UserButton
            showName
            appearance={{
              elements: {
                userButtonBox: {
                  flexFlow: "row-reverse",
                },
              },
            }}
          />
        </Flex>
      </Stack>
    </Paper>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MobileNavbar(props: PaperProps) {
  return (
    <Paper
      withBorder
      component="nav"
      w={80}
      className="flex-shrink-0"
      {...props}
    >
      <Stack h={"100%"} gap={0}>
        <Center p={16} py={38}>
          <img src={icon} alt="Museum Nord icon" className="h-10" />
        </Center>

        <Divider />

        <Stack flex={1} gap={4}>
          <Link to="/">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Bookinger`}
                icon={IconReceipt}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/products">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Produkt`}
                icon={IconStar}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/employees">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Personell`}
                icon={IconUsers}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/login/$">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Produkt`}
                icon={IconStar}
                active={isActive}
              />
            )}
          </Link>
          <Link to="/login/$">
            {({ isActive }) => (
              <MobileNavbarLink
                label={t`Lokasjoner`}
                icon={IconMapPin}
                active={isActive}
              />
            )}
          </Link>
        </Stack>

        <Divider />

        <Center p={16} bg="white">
          <UserButton
            appearance={{
              elements: {
                userButtonAvatarBox: {
                  width: 32,
                  height: 32,
                },
              },
            }}
          />
        </Center>
      </Stack>
    </Paper>
  );
}

function MobileNavbarLink({
  icon: Icon,
  label,
  active,
}: {
  label: string;
  icon: IconType;
  active: boolean;
}) {
  return (
    <Center>
      <Tooltip label={label} position="right">
        <ActionIcon size={60} variant={active ? "light" : "subtle"}>
          <Icon size={28} />
        </ActionIcon>
      </Tooltip>
    </Center>
  );
}
