import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  getGetCategoriesQueryKey,
  useCreateCategory,
} from "@/shared/api/generated/categories";

const FormSchema = z.object({
  name: z.string().min(1, { message: t`Velg navn` }),
});

type NewCategoryFormSchema = z.infer<typeof FormSchema>;

export default function NewCategoryForm({ close }: { close: () => void }) {
  const form = useForm<NewCategoryFormSchema>({
    mode: "uncontrolled",
    initialValues: {
      name: "",
    },
    validate: zodResolver(FormSchema),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: createCategory } = useCreateCategory();

  async function handleSubmit(values: NewCategoryFormSchema) {
    await createCategory(
      {
        data: {
          name: values.name,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetCategoriesQueryKey(),
          });
          close();
        },
      },
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label={t`Navn`}
          placeholder={t`Velg navn`}
          withAsterisk
          {...form.getInputProps("name")}
        />

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" onClick={close}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
