import { Trans, useLingui } from "@lingui/react/macro";
import { Paper, Text, Textarea } from "@mantine/core";
import { createFormActions, useForm, zodResolver } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useBlocker } from "@tanstack/react-router";
import { z } from "zod";

import {
  getGetBookingQueryKey,
  getGetBookingsQueryKey,
  useUpdateBookingDetailedInformation,
} from "@/shared/api/generated/booking";
import {
  GetBookingData,
  GetBookingResponse,
  GetBookingsResponse,
} from "@/shared/api/generated/schemas";

const additionalInformationBookingFormSchema = z.object({
  external: z.string(),
  internal: z.string(),
});

type AdditionalInformationBookingFormInitial = z.infer<
  typeof additionalInformationBookingFormSchema
>;

const ADDITIONAL_INFORMATION_FORM_NAME = "additional-information-booking-form";

export const additionaInformationFormActions =
  createFormActions<AdditionalInformationBookingFormInitial>(
    ADDITIONAL_INFORMATION_FORM_NAME,
  );

export default function AdditionalInformation({
  booking,
}: {
  booking: GetBookingData;
}) {
  const { t } = useLingui();
  const debouncedSubmit = useDebouncedCallback(() => {
    form.onSubmit(handleSubmit)();
  }, 1000);

  const form = useForm<AdditionalInformationBookingFormInitial>({
    name: ADDITIONAL_INFORMATION_FORM_NAME,
    mode: "controlled",
    validate: zodResolver(additionalInformationBookingFormSchema),
    initialValues: {
      external: booking.externalInformation ?? "",
      internal: booking.internalInformation ?? "",
    },
    onValuesChange: debouncedSubmit,
  });

  useBlocker({
    blockerFn: () =>
      window.confirm(
        t`Det er gjort endringer i skjemaet som ikke er lagret. Trykk "OK" for å forkaste endringene.`,
      ),
    condition: form.isDirty(),
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateBookingDetailedInformation } =
    useUpdateBookingDetailedInformation({
      mutation: {
        onSuccess: (data) => {
          queryClient.setQueryData<GetBookingResponse>(
            getGetBookingQueryKey(booking.id),
            data,
          );
          // Update this booking in the for GET /bookings
          queryClient.setQueriesData<GetBookingsResponse>(
            { queryKey: getGetBookingsQueryKey() },
            (old) => ({
              ...old,
              data: (old?.data ?? []).map((booking) =>
                booking.id === data.data.id ? data.data : booking,
              ),
            }),
          );
        },
      },
    });

  async function handleSubmit(values: AdditionalInformationBookingFormInitial) {
    console.log(values);

    await updateBookingDetailedInformation({
      id: booking.id,
      data: {
        externalInformation: values.external,
        internalInformation: values.internal,
        updatedAt: booking.updatedAt,
      },
    });

    form.resetDirty(values);
  }

  return (
    <Paper p="xs" h={"100%"}>
      <Text size="sm" mb="sm">
        <Trans>Detaljert informasjon</Trans>
      </Text>

      <Textarea
        label={t`Ekstern informasjon`}
        placeholder={t`Ekstern informasjon`}
        description={t`Kommer med på informasjon ut til kunde.`}
        autosize
        minRows={6}
        {...form.getInputProps("external")}
        key={form.key("external")}
      />
      <Textarea
        label={t`Intern informasjon`}
        placeholder={t`Intern informasjon`}
        description={t`Kommer bare med på dokument til interne.`}
        autosize
        minRows={6}
        {...form.getInputProps("internal")}
        key={form.key("internal")}
      />
    </Paper>
  );
}
