import { useState } from "react";
import { type I18n } from "@lingui/core";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { Button, Modal, SegmentedControl, Switch } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { IconFile, IconFileDownload } from "@tabler/icons-react";
import dayjs from "dayjs";

import { GetBookingData } from "@/shared/api/generated/schemas";

import { PDFSummary } from "./pdf-summary";

type PDFLanguage = "nb" | "en";

const pdfLanguageOptions = (
  i18n: I18n,
): { label: string; value: PDFLanguage }[] => [
  { label: i18n.t(msg`Norsk`), value: "nb" },
  { label: i18n.t(msg`Engelsk`), value: "en" },
];

export default function PDFSummaryModal({
  booking,
  type,
}: {
  booking: GetBookingData;
  type: "internal" | "external";
}) {
  const { i18n } = useLingui();
  const [opened, { open, close }] = useDisclosure(false);
  const [language, setLanguage] = useState<PDFLanguage>("nb");
  const [showPrice, setShowPrice] = useState(true);

  const title = `${dayjs(booking.arrival).format("DDMMYY")}-${booking.id}${type === "internal" ? "-I" : ""}`;

  return (
    <>
      <Button
        leftSection={<IconFile />}
        variant={type === "internal" ? "outline" : "filled"}
        onClick={open}
      >
        <Trans>
          {type === "internal"
            ? t`Se internt dokument`
            : t`Se ekstern dokument`}
        </Trans>
      </Button>

      <Modal.Root opened={opened} size={"100%"} onClose={close}>
        <Modal.Overlay />
        <Modal.Content
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Modal.Header style={{ display: "flex", gap: "16px" }}>
            <PDFDownloadLink
              fileName={title}
              document={
                <PDFSummary
                  title={title}
                  type={type}
                  language={language}
                  booking={booking}
                  showPrice={showPrice}
                />
              }
            >
              <Button leftSection={<IconFileDownload />}>
                <Trans>Last ned</Trans>
              </Button>
            </PDFDownloadLink>
            <SegmentedControl
              value={language}
              onChange={(value) => {
                setLanguage(value as PDFLanguage);
              }}
              data={pdfLanguageOptions(i18n)}
            />
            <Switch
              label={t`Vis priser`}
              checked={showPrice}
              onChange={(event) => setShowPrice(event.currentTarget.checked)}
            />
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body
            style={{
              flex: 1,
            }}
          >
            <PDFViewer width="100%" height="100%" style={{ border: "none" }}>
              <PDFSummary
                title={title}
                type={type}
                language={language}
                booking={booking}
                showPrice={showPrice}
              />
            </PDFViewer>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
