import { Trans } from "@lingui/react/macro";
import { Modal, Title } from "@mantine/core";

import { GetCategoriesItem } from "@/shared/api/generated/schemas";

import UpdateCategoryForm from "./update-category-form";

export default function UpdateCategoryModal({
  category,
  opened,
  close,
}: {
  category: GetCategoriesItem;
  opened: boolean;
  close: () => void;
}) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Endre kategori</Trans>
          </Title>
        }
      >
        <UpdateCategoryForm category={category} close={close} />
      </Modal>
    </>
  );
}
