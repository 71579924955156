import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  getGetLocationsQueryKey,
  useCreateLocation,
} from "@/shared/api/generated/locations";

const createLocationformSchema = z.object({
  name: z.string().min(1, { message: t`Velg navn` }),
});

type CreateLocationFormSchema = z.infer<typeof createLocationformSchema>;

interface CreateLocationFormProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

export function CreateLocationForm({
  onSuccess,
  onCancel,
}: CreateLocationFormProps) {
  const form = useForm<CreateLocationFormSchema>({
    mode: "uncontrolled",
    initialValues: {
      name: "",
    },
    validate: zodResolver(createLocationformSchema),
  });

  const queryClient = useQueryClient();
  const { mutateAsync: createLocation } = useCreateLocation();

  async function handleSubmit(values: CreateLocationFormSchema) {
    await createLocation(
      {
        data: {
          name: values.name,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: getGetLocationsQueryKey(),
          });
          onSuccess?.();
        },
      },
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label={t`Navn`}
          placeholder={t`Velg navn`}
          withAsterisk
          {...form.getInputProps("name")}
        />

        <Group justify="end" gap={8} mt={8}>
          <Button variant="subtle" type="button" onClick={onCancel}>
            <Trans>Avbryt</Trans>
          </Button>
          <Button type="submit">
            <Trans>Opprett</Trans>
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
