import { Trans } from "@lingui/react/macro";
import { Box, Flex, Group } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";
import { fallback, zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

import BookingsTable from "@/features/bookings/bookings-table";
import CreateBooking from "@/features/bookings/create-booking/create-booking-modal";

// import RegisterArrival from "@/features/bookings/register-arrival/register-arrival";
const bookingsSearchSchema = z.object({
  includeCancelled: fallback(z.boolean().optional(), undefined),
});

export const Route = createFileRoute("/_auth-layout/bookings/")({
  component: Index,
  validateSearch: zodValidator(bookingsSearchSchema),
});

function Index() {
  return (
    <Box p="lg">
      <Flex align="center" justify="space-between">
        <h1>
          <Trans>Bookinger</Trans>
        </h1>

        <Group>
          {/* <RegisterArrival /> */}
          <CreateBooking />
        </Group>
      </Flex>

      <BookingsTable />
    </Box>
  );
}
