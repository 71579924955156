import { Trans } from "@lingui/react/macro";
import { Modal, Text, Title } from "@mantine/core";

import { GetProductsItem } from "@/shared/api/generated/schemas";

import UpdateProductForm from "./update-product-form";

export default function UpdateProductModal({
  product,
  opened,
  close,
}: {
  product: GetProductsItem;
  opened: boolean;
  close: () => void;
}) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        title={
          <Title size="sm">
            <Trans>Oppdater produkt</Trans>
          </Title>
        }
      >
        <Text size="sm" c="dimmed" mb={8}>
          <Trans>Standardverdier. Alt kan overstyres i enkelt-bookinger.</Trans>
        </Text>

        <UpdateProductForm
          product={product}
          onSuccessCallback={close}
          onClose={close}
        />
      </Modal>
    </>
  );
}
