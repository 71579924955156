/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */

export type GetBookingDataStatus = typeof GetBookingDataStatus[keyof typeof GetBookingDataStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBookingDataStatus = {
  cancelled: 'cancelled',
  request: 'request',
  reserved: 'reserved',
  confirmed: 'confirmed',
  confirmationsent: 'confirmationsent',
  arrived: 'arrived',
} as const;
