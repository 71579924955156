import { msg } from "@lingui/core/macro";
import { type I18n, type MessageDescriptor } from "@lingui/core";

import { CreateBookingRequestVisitCategory } from "@/shared/api/generated/schemas";

export const createBookingVisitCategoryLabels: Record<
  Exclude<CreateBookingRequestVisitCategory, null>,
  MessageDescriptor
> = {
  event: msg`Hendelse`,
  online: msg`Online`,
  company: msg`Bedrift`,
  school: msg`Skole`,
  kindergarten: msg`Barnehage`,
  compschool: msg`Videregående skole`,
  cruise: msg`Cruise`,
  hurtigruten: msg`Hurtigruten`,
  havila: msg`Havila`,
  tour: msg`Tur`,
  local: msg`Lokal`,
  freeentry: msg`Fri entré`,
  conference: msg`Konferanse`,
  hikinggroup: msg`Turgruppe`,
} as const;

export const createBookingVisitCategoryOptions = (i18n: I18n) =>
  Object.values(CreateBookingRequestVisitCategory).map((value) => ({
    value,
    label: i18n.t(createBookingVisitCategoryLabels[value]),
  }));
