/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Template API
 * Something something. <br><br>The spec is available at <a href='/swagger/api.json'>/swagger/api.json</a>.
 * OpenAPI spec version: 0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import type {
  ApiAppError,
  CreateLocationRequest,
  CreateLocationResponse,
  GetLocationResponse,
  GetLocationsResponse,
  UpdateLocationRequest,
  UpdateLocationResponse
} from './schemas'
import { useApi } from '../client';
import type { ErrorType, BodyType } from '../client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Create Location
 */
export const useCreateLocationHook = () => {
        const createLocation = useApi<CreateLocationResponse>();

        return useCallback((
    createLocationRequest: BodyType<CreateLocationRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return createLocation(
          {url: `/location`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createLocationRequest, signal
    },
          options);
        }, [createLocation])
      }
    


export const useCreateLocationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>, TError,{data: BodyType<CreateLocationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>, TError,{data: BodyType<CreateLocationRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const createLocation =  useCreateLocationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>, {data: BodyType<CreateLocationRequest>}> = (props) => {
          const {data} = props ?? {};

          return  createLocation(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateLocationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>>
    export type CreateLocationMutationBody = BodyType<CreateLocationRequest>
    export type CreateLocationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Create Location
 */
export const useCreateLocation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>, TError,{data: BodyType<CreateLocationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateLocationHook>>>,
        TError,
        {data: BodyType<CreateLocationRequest>},
        TContext
      > => {

      const mutationOptions = useCreateLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Location
 */
export const useDeleteLocationHook = () => {
        const deleteLocation = useApi<void>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return deleteLocation(
          {url: `/location/${id}`, method: 'DELETE'
    },
          options);
        }, [deleteLocation])
      }
    


export const useDeleteLocationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const deleteLocation =  useDeleteLocationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteLocation(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLocationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>>
    
    export type DeleteLocationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Delete Location
 */
export const useDeleteLocation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>, TError,{id: string}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteLocationHook>>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = useDeleteLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Location
 */
export const useGetLocationHook = () => {
        const getLocation = useApi<GetLocationResponse>();

        return useCallback((
    id: string,
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getLocation(
          {url: `/location/${id}`, method: 'GET', signal
    },
          options);
        }, [getLocation])
      }
    

export const getGetLocationQueryKey = (id: string,) => {
    return [`/location/${id}`] as const;
    }

    
export const useGetLocationQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError = ErrorType<ApiAppError>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLocationQueryKey(id);

  const getLocation =  useGetLocationHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>> = ({ signal }) => getLocation(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetLocationQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>>
export type GetLocationQueryError = ErrorType<ApiAppError>


export function useGetLocation<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetLocation<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetLocation<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Location
 */

export function useGetLocation<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError = ErrorType<ApiAppError>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = useGetLocationQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Location
 */
export const useUpdateLocationHook = () => {
        const updateLocation = useApi<UpdateLocationResponse>();

        return useCallback((
    id: string,
    updateLocationRequest: BodyType<UpdateLocationRequest>,
 options?: SecondParameter<ReturnType<typeof useApi>>,) => {
        return updateLocation(
          {url: `/location/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateLocationRequest
    },
          options);
        }, [updateLocation])
      }
    


export const useUpdateLocationMutationOptions = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>, TError,{id: string;data: BodyType<UpdateLocationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>, TError,{id: string;data: BodyType<UpdateLocationRequest>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      const updateLocation =  useUpdateLocationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>, {id: string;data: BodyType<UpdateLocationRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateLocation(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateLocationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>>
    export type UpdateLocationMutationBody = BodyType<UpdateLocationRequest>
    export type UpdateLocationMutationError = ErrorType<ApiAppError>

    /**
 * @summary Update Location
 */
export const useUpdateLocation = <TError = ErrorType<ApiAppError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>, TError,{id: string;data: BodyType<UpdateLocationRequest>}, TContext>, request?: SecondParameter<ReturnType<typeof useApi>>}
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateLocationHook>>>,
        TError,
        {id: string;data: BodyType<UpdateLocationRequest>},
        TContext
      > => {

      const mutationOptions = useUpdateLocationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Locations
 */
export const useGetLocationsHook = () => {
        const getLocations = useApi<GetLocationsResponse>();

        return useCallback((
    
 options?: SecondParameter<ReturnType<typeof useApi>>,signal?: AbortSignal
) => {
        return getLocations(
          {url: `/locations`, method: 'GET', signal
    },
          options);
        }, [getLocations])
      }
    

export const getGetLocationsQueryKey = () => {
    return [`/locations`] as const;
    }

    
export const useGetLocationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = ErrorType<ApiAppError>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLocationsQueryKey();

  const getLocations =  useGetLocationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>> = ({ signal }) => getLocations(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetLocationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>>
export type GetLocationsQueryError = ErrorType<ApiAppError>


export function useGetLocations<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = ErrorType<ApiAppError>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetLocations<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetLocations<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get Locations
 */

export function useGetLocations<TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = ErrorType<ApiAppError>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>>, request?: SecondParameter<ReturnType<typeof useApi>>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = useGetLocationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



