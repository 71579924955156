import { Trans } from "@lingui/react/macro";
import { Box, Flex } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

import CreateProduct from "@/features/products/create-product";
import ProductsTable from "@/features/products/products-table";

export const Route = createFileRoute("/_auth-layout/products/")({
  component: ProductsPage,
});

function ProductsPage() {
  return (
    <Box p="lg">
      <Flex align="center" justify="space-between">
        <h1>
          <Trans>Produkt</Trans>
        </h1>

        <CreateProduct />
      </Flex>

      <ProductsTable />
    </Box>
  );
}
